import {
  LoginRNRRouteData,
  getLoginRNRRouteData,
} from "~/server/data/login_rnr_route_data";
import { cache, createAsync } from "@solidjs/router";
import { Accessor, onMount } from "solid-js";
import { LoginRnRStar, ribbon } from "~/assets/assets";
import LoginForm from "~/components/login-rnr/login_form";
import { BrandingMinimal } from "~/widgets/branding";
import { rnrEventManager } from "~/data/events";
import { getClientIdFromCookie } from "~/utils/common";

const getLoginRNRRouteData$C = cache(getLoginRNRRouteData, "login");

export default function LoginRNRRoute() {
  const routeData: Accessor<LoginRNRRouteData | undefined> = createAsync(() =>
    getLoginRNRRouteData$C()
  );

  onMount(() => {
    rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
  });

  return (
    <div class="lg:bg-[linear-gradient(180deg,_#FCFBF7_0%,_#F7F4EB_100%)]">
      <div class="md:flex md:h-screen md:justify-center md:bg-loginRNRCircleLG md:bg-[center_top] md:bg-no-repeat">
        <div class="flex flex-col md:min-h-screen md:w-[375px]">
          <div class="relative mx-0 -mb-6 mt-0 h-[356px] ">
            <div class="md:bgImageNone absolute left-0 top-0 h-full w-full bg-loginRNRCircle bg-[center_top] bg-no-repeat "></div>
            <div class="relative z-10 flex h-full w-full flex-col items-center justify-center">
              <div class="absolute left-2/4 top-1/4 flex h-[106px] w-[106px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border-[1px] border-[rgba(254,204,145,0.45)] bg-white p-4 [box-shadow:0px_0px_20px_0px_rgba(255,_196,_127,_0.30)]">
                <img src={routeData()?.clientLogoUrl} class="w-full" alt={""} />
              </div>
              <div class="absolute left-2/4 top-[65%] flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-2">
                <div class="flex items-center justify-center gap-2 ">
                  <div class="h-2 w-2">
                    <LoginRnRStar />
                  </div>
                  <div class="h-3.5 w-3.5">
                    <LoginRnRStar />
                  </div>
                  <p class="font-sacramento text-h1 font-normal leading-[52px] tracking-normal text-orange">
                    Welcome
                  </p>
                  <div class="h-3.5 w-3.5">
                    <LoginRnRStar />
                  </div>
                  <div class="h-2 w-2">
                    <LoginRnRStar />
                  </div>
                </div>
                <h1 class="text-center text-h3 text-basePrimaryDark">
                  Redeem coins to shop <br />
                  on 400+ brands
                </h1>
                <div class="mt-2 w-28">
                  <img src={ribbon} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="mx-4 rounded-2xl border border-baseTertiaryDark bg-white py-4 md:mx-auto md:w-[372px]">
            <LoginForm
              clientId={() => routeData()?.clientId!}
              clientSecret={() => routeData()?.clientSecret!}
              authMethod={() => routeData()?.authMethod!}
              mode={() => routeData()?.mode!}
            />
          </div>
          <div class="absolute bottom-[0] left-2/4 -translate-x-1/2 md:relative">
            <div class="my-3 flex items-center justify-center">
              <BrandingMinimal styles="text-textNormal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
